.map-height {
  height: 100%;
}

.map-wrap {
  position: relative;
  display: flex;
  margin-top: 160px;
  width: 100%;
  height: 100%;
  background: #eee;
}

.map {
  position: absolute;
  width: calc(((100vw - #{$global-width}) / 2) + 100%;
  height: 100%;
  background: #eee;
}

/* Small Devices, Tablets */
@media only screen and (max-width : 1024px) {

  .map-height {
    flex-direction: row;

    & > div {
      height: 70%;
    }

  }

  .map-wrap {
    margin-top: 0px;
  }


}


/* Small Devices, Tablets */
@media only screen and (max-width : 760px) {

  .map-wrap {
    display: none;
  }

}
