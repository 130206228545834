.header {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;

  &_unfix {
    position: relative;
  }

  &_show {
    display: block!important;
  }

  &_md-show {
    display: none;
  }

}

.header-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.header-logo-wrap {
  margin: 44px 0 0 30px;
}

.header-menu-btn-wrap {
  position: absolute;
  top: 27px;
  left: 50%;
  margin-left: -30px;
  width: 60px;
  text-align: center;
}

.header-menu-btn {
  display: inline-block;
  //max-width: 60px;
  height: 75px;
  background: transparent;
  border: 0;
  outline: 0;
  line-height: 0.6;
  text-align: center;
  cursor: pointer;

  $g: &;

  &_2 {
    #{$g}__ic-wrap {

      &:before {
        background: #ffffff;
      }

      &:after {
        background: #ffffff;
      }

    }
  }

  &_3 {
    #{$g}__ic-wrap {

      &:before {
        background: #8033e0;
      }

      &:after {
        background: #8033e0;
      }

    }
  }

  &.toggled {

    #{$g}__ic-wrap {

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }

    }

  }

  &__ic-wrap {
    position: relative;
    display: inline-block;
    height: 21px;
    width: 16px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px;
      width: 2px;
      height: 21px;
      background: #6ed1cd;
      transition: transform .2s ease-in-out;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px;
      width: 2px;
      height: 21px;
      background: #6ed1cd;
      transition: transform .2s ease-in-out;
    }

  }

  &__text {
    display: block;
    margin-top: 13px;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 14px;
    color: #3b3b3d;
    text-transform: lowercase;
  }

}

.header-contact-wrap {
  margin: 55px 66px 0 0;
}

.header-contact {
  position: relative;
  display: inline-block;
  padding: 0 0 6px 0;
  font-family: $font-family-one;
  font-weight: 800;
  font-size: 14px;
  color: #3b3b3d;
  text-transform: lowercase;
  text-decoration: none;
  line-height: 0.6;
  background: transparent;
  border-style: none none none none;
  border-bottom: 2px solid #6ed1cd;
  outline: none;
  cursor: pointer;

  &_2 {
    border-bottom: 2px solid #ffffff;
  }

  &_3 {
    border-bottom: 2px solid #8033e0;
  }

  &_4 {
    border-bottom: 2px solid #6ed1cd;
  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1024px) {

  .header {
    display: none;

    &_md-show {
      display: block;
      background: #fff;
    }

  }

  .header-logo-wrap {
    margin: 40px 0 0 40px;
  }

  .header-wrap {
    padding-bottom: 20px;
  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

  .header-menu-btn-wrap {
    top: 36px;
  }

  .header-menu-btn {

    &__ic-wrap {
      height: 38px;
      width: 38px;

      &:before {
        margin-left: -2px;
        width: 4px;
        height: 38px;
      }

      &:after {
        margin-left: -2px;
        width: 4px;
        height: 38px;
      }

    }

    &__text {
      margin-top: 15px;
      font-size: 26px;
    }

  }

  .header-contact-wrap {
    margin: 90px 40px 0 0;
  }

  .header-contact {
    padding: 0 0 12px 0;
    font-size: 26px;
    border-bottom-width: 4px;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .header-wrap {
    padding-bottom: 20px;
  }

  .header-logo-wrap {
    margin: 40px 0 0 25px;
  }

  .header-menu-btn-wrap {
    top: 26px;
  }

  .header-menu-btn {
    height: 50px;

    &__ic-wrap {
      height: 21px;
      width: 21px;

      &:before {
        margin-left: -1px;
        width: 2px;
        height: 21px;
      }

      &:after {
        margin-left: -1px;
        width: 2px;
        height: 21px;
      }

    }

    &__text {
      margin-top: 15px;
      font-size: 14px;
    }

  }

  .header-contact-wrap {
    margin: 60px 25px 0 0;
  }

  .header-contact {
    padding: 0 0 6px 0;
    font-size: 14px;
    border-bottom-width: 2px;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}
