/* Ion.RangeSlider, Simple Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// © guybowden, 2014        https://github.com/guybowden
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs {
  height: 55px;
  margin-bottom: 50px;
}

.irs-with-grid {
  height: 75px;
}

.irs-line {
  height: 7px;
  top: 33px;
  background: #8033e0;
  //background: linear-gradient(to bottom, #DDD -50%, #FFF 150%); /* W3C */
  border: 1px solid #8033e0;
  border-radius: 3px;
  //-moz-border-radius: 3px;
}

.irs-line-left {
  height: 8px;
}

.irs-line-mid {
  height: 8px;
}

.irs-line-right {
  height: 8px;
}

.irs-bar {
  height: 7px;
  top: 33px;
  border-top: 1px solid #6ed1cd;
  border-bottom: 1px solid #6ed1cd;
  background: #6ed1cd;
  /*background: linear-gradient(to top, rgba(66,139,202,1) 0%,rgba(127,195,232,1) 100%); !* W3C *!*/
}

.irs-bar-edge {
  height: 7px;
  top: 33px;
  width: 14px;
  border: 1px solid #6ed1cd;
  border-right: 0;
  background: #6ed1cd;
  /*background: linear-gradient(to top, rgba(66,139,202,1) 0%,rgba(127,195,232,1) 100%); !* W3C *!*/
  border-radius: 3px 0 0 3px;
  /*-moz-border-radius: 16px 0 0 16px;*/
}

.irs-shadow {
  height: 2px;
  top: 38px;
  background: #000;
  opacity: 0.3;
  border-radius: 3px;
  //-moz-border-radius: 3px;
}

.lt-ie9 .irs-shadow {
  filter: alpha(opacity=30);
}

.irs-slider {
  top: 23px;
  width: 26px;
  height: 26px;
  //border: 1px solid #6ed1cd;
  background: #6ed1cd;
  //background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(220,220,220,1) 20%,rgba(255,255,255,1) 100%); /* W3C */
  border-radius: 27px;
  -moz-border-radius: 27px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: background ease-in-out .2s;
  cursor: pointer;
}

.irs-slider.state_hover, .irs-slider:hover {
  //border: 1px solid #8033e0;
  background: #8033e0;
}

.irs-min, .irs-max {
  color: #333;
  font-size: 12px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 5px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  -moz-border-radius: 3px;
}

.lt-ie9 .irs-min, .lt-ie9 .irs-max {
  background: #ccc;
}

.irs-from, .irs-to, .irs-single {
  color: #fff;
  font-size: 14px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background: #6ed1cd;
  border-radius: 3px;
  -moz-border-radius: 3px;
}

.lt-ie9 .irs-from, .lt-ie9 .irs-to, .lt-ie9 .irs-single {
  background: #999;
}

.irs-grid {
  height: 27px;
  top: 0;
  bottom: auto;
}

.irs-grid-pol {
  opacity: 0;
  background: #6ed1cd;
}

.irs-grid-pol.small {
  background: #999;
}

.irs-grid-text {
  top: 5px;
  font-weight: 800;
  color: #3a3a3b;
  font-size: 16px;
}

.irs-disabled {
}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .irs {
    margin-bottom: 0;
  }

  .irs-grid-text {
    top: 5px;
    font-weight: 800;
    color: #3a3a3b;
    font-size: 12px;
  }

}
