.btn {
  position: relative;
  display: inline-block;
  padding: 17px 43px;
  font-family: $font-family-one;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: .2px;
  color: #3b3b3d;
  text-decoration: none;
  border: 2px solid #6ed1cd;
  outline: none;
  cursor: pointer;
  background: transparent;
  transition: all ease-in-out .2s;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -11px;
    width: 13px;
    height: 2px;
    background: #6ed1cd;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    right: -11px;
    width: 13px;
    height: 2px;
    background: #6ed1cd;
  }

  &:hover {
    opacity: .7;
  }

  &_2 {
    border: 2px solid #8033e0;

    &:before {
      background: #8033e0;
    }

    &:after {
      background: #8033e0;
    }

  }

}


/* Medium Devices, Desktops */
@media only screen and (max-width: 1024px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .btn {
    position: relative;
    display: inline-block;
    padding: 10px 23px;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}
