#worksheet-wrap {
  background: #e0e6ee;
  opacity: 0;
  height: 0;
  outline: none;
  transition: all .2s ease-in-out;

  &.toggled {
    visibility: visible;
    opacity: 1;
    height: 100vh;
  }

}

.worksheet-success {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fade-in .6s;

  & h2 {
    margin: 0 0 70px 0;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 30px;
    color: #3a3a3b;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: .2px;
    text-align: center;
  }

  & p {
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 14px;
    color: #3a3a3b;
    line-height: normal;
    letter-spacing: .2px;
    text-align: center;
  }

}

#worksheet-form {
  position: relative;
  width: 100%;
  overflow: hidden;
}

#worksheet-question {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  height: 0;

  &.toggled {
    visibility: visible;
    opacity: 1;
    height: 100vh;
  }

}

.worksheet-question-box {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -300px;
  width: 600px;
  min-height: 500px;
  transition: all .3s ease-in-out;
  animation: fade-in .6s;


  @keyframes fade-in {

    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &_current {

  }

  &_prev {
    left: 0;
    opacity: 0;
    transform: translateX(100%);
  }

  &__title {
    margin: 0 0 35px 0;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 30px;
    color: #3a3a3b;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: .2px;
  }

  &__answers {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__btn {
    display: inline-block;
    position: relative;
    margin-top: 50px;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 16px;
    color: #3b3b3d;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    transition: padding-left ease-in-out .2s;

    &:hover {
      padding-left: 10px;
    }

    & span {
      position: absolute;
      top: 8px;
      right: -410px;
      width: 400px;
      height: 2px;
      background: #6ed1cd;

      &:before {
        content: '';
        position: absolute;
        top: -9px;
        right: 14px;
        width: 2px;
        height: 12px;
        background: #6ed1cd;
        transform: rotate(-45deg);
      }

      &:after {
        content: '';
        position: absolute;
        top: -9px;
        right: 6px;
        width: 2px;
        height: 12px;
        background: #6ed1cd;
        transform: rotate(-45deg);
      }

    }

    &_2 span {
      background: #fff !important;

      &:before {
        background: #fff !important;
      }

      &:after {
        background: #fff !important;
      }

    }

    &_3 span {
      background: #8033e0 !important;

      &:before {
        background: #8033e0 !important;
      }

      &:after {
        background: #8033e0 !important;
      }

    }
  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

}

@media only screen and (max-width: 992px) and (orientation: landscape) {

  #worksheet-wrap {

    &.toggled {
      height: 100%;
    }

  }

  #worksheet-question {

    &.toggled {
      height: 100%;
    }

  }

  .worksheet-question-box {
    position: relative;
    top: auto;
    left: auto;
    margin-top: auto;
    margin-left: auto;
    padding: 50px;
  }

  .worksheet-question-box {
    position: relative;
    top: auto;
    left: auto;
    margin-top: auto;
    margin-left: auto;
    padding: 25px;
    width: 100%;
    min-height: auto;

    &_prev {
      position: absolute;
    }

    &__answers {
      min-height: auto;
    }

  }

  .worksheet-success {
    height: 100%;
    padding: 50px;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  #worksheet-wrap {

    &.toggled {
      height: 100%;
    }

  }

  #worksheet-question {

    &.toggled {
      height: 100%;
    }

  }

  .worksheet-question-box {
    position: relative;
    top: auto;
    left: auto;
    margin-top: auto;
    margin-left: auto;
    padding: 25px;
    width: 100%;
    min-height: auto;

    &_current {

    }

    &_prev {
      position: absolute;
      left: 0;
      opacity: 0;
      transform: translateX(100%);
    }

    &__title {
      margin: 0 0 35px 0;
      font-size: 18px;
    }

    &__answers {
      min-height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__btn {
      margin-top: 0;
      font-size: 16px;

      & span {
        top: 8px;
        right: -110px;
        width: 100px;
      }

    }

  }

  .worksheet-success {
    height: 100%;
    padding: 25px;

    & h2 {
      margin: 0 0 25px 0;
      font-size: 18px;
    }

    & p {
      font-size: 14px;
    }

  }


}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}

