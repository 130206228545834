.menu {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 2;
  width: 100%;
  height: 0;
  background: #fff;
  transition: all .3s ease-in-out;

  &.toggled {
    visibility: visible;
    opacity: 1;
    height: 100%;
  }

  $g: &;

  &_1 {
    background: #fff;
  }

  &_2 {
    background: #6ed1cd;

    #{$g}__sub-title {
      color: #ffffff;
    }

  }

  &_3 {
    background: #e0e6ee;
  }

  &_4 {
    background: #8033e0;
  }

  &__sub-title {
    position: fixed;
    left: -243px;
    bottom: 190px;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 208px;
    color: #3b3b3d;
    text-transform: uppercase;
    letter-spacing: .2px;
    transform: rotate(-90deg);
  }

}

.menu-nav {
  padding-top: 190px;
}

.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    margin-bottom: 20px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

  }

  &__link {
    $g: &;

    position: relative;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 36px;
    color: #3b3b3d;
    letter-spacing: .2px;
    text-decoration: none;
    transition: all .2s ease-in-out;

    &:before {
      content: '#';
      position: absolute;
      top: calc(50% + 4px);
      left: 0;
      transform: translateY(-50%);
      line-height: 22px;
      opacity: 0;
      transition: all .2s ease-in-out;
    }

    &:hover {
      padding-left: 33px;
      color: #3b3b3d;
      &:before {
        content: '#';
        position: absolute;
        top: calc(50% + 4px);
        left: 0;
        transform: translateY(-50%);
        line-height: 22px;
        font-size: inherit;
        color: #6ed1cd;
        opacity: 1;
      }
    }

    &_active {
      padding-left: 33px;
      color: #3b3b3d;
      &:before {
        content: '#';
        position: absolute;
        left: 0;
        font-size: inherit;
        color: #6ed1cd;
        opacity: 1;
      }

    }

  }

}

.menu {

  &_1 {

    .menu-list {

      &__link {

        &:hover {
          &:before {
            color: #6ed1cd;
          }
        }

        &_active {
          &:before {
            color: #6ed1cd;
          }
        }

      }

    }

  }

  &_2 {

    .menu-list {

      &__link {

        &:hover {
          &:before {
            color: #fff;
          }
        }

        &_active {
          &:before {
            color: #fff;
          }
        }

      }

    }

  }

  &_3 {

    .menu-list {

      &__link {

        &:hover {
          &:before {
            color: #8033e0;
          }
        }

        &_active {
          &:before {
            color: #8033e0;
          }
        }

      }

    }

  }

  &_4 {

    .menu-list {

      &__link {

        &:hover {
          &:before {
            color: #6ed1cd;
          }
        }

        &_active {
          &:before {
            color: #6ed1cd;
          }
        }

      }

    }

  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

  .menu {

    &__sub-title {
      left: 50%;
      margin-left: -347px;
      bottom: -55px;
      font-size: 226px;
      transform: rotate(0deg);
    }

  }

  .menu-nav {
    //padding-top: 270px;
    padding-top: 200px;
    padding-left: 0;

  }

  .menu-list {

    &__item {
      margin-bottom: 50px;
      text-align: center;
    }

    &__link {
      font-size: 30px;

      &:hover {
        padding-left: 33px;
      }

      &_active {
        padding-left: 33px;
      }

    }

  }

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .menu {

    &__sub-title {
      left: 50%;
      margin-left: -153px;
      bottom: -22px;
      font-size: 100px;
    }

  }

  .menu-nav {
    padding-top: 165px;
  }

  .menu-list {

    &__item {
      margin-bottom: 25px;
      text-align: center;
    }

    &__link {
      font-size: 16px;

      &:hover {
        padding-left: 13px;
      }

      &_active {
        padding-left: 13px;
      }

    }

  }

}
