.content-b {
  position: relative;
  width: 100%;
  height: 100vh;
  outline: 0;

  &_2 {
    background: #6ed1cd;
  }

  &_3 {
    background: #e0e6ee;
  }

  &_4 {
    background: #8033e0;
  }

  &__container {
    clip: rect(auto, auto, auto, auto);
    position: absolute;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }

  &__wrap {
    width: 100%;

    &_fix {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    }

  }

  &__box {
    max-width: 760px;
  }
  &__time{
   position: relative;
   width: 100%;
   z-index: 1;
   overflow: hidden;
   margin-left: -25px;
 }
 &__people{
  position: relative;
  width: 100%;
    // height: 372px;
    // background:transparent url('/assets/img/people.svg') center center no-repeat;
    z-index: 1;
    overflow: hidden;
    margin-left: -25px;
  }

  &__title {
    display: inline-block;
    margin: 0;
    position: relative;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 208px;
    text-transform: uppercase;
    letter-spacing: .2px;
    // background-image: url("/assets/img/money/word.gif");
    background-image: url('/assets/img/linesvlad.svg');
    background-repeat: repeat;
    background-color: #3b3b3d;
    background-size: 782px;
    background-position: 0px 100%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: swim1 20s linear infinite;
    @keyframes swim1 {
      from { background-position: 0px 100%}
      to { background-position: -1082px 100%}
    }
    &_2 {
      background-image: url("/assets/img/time/word.gif");
    }
    &_3 {
      background-image: url("/assets/img/dotsvg.svg");
      background-size: 18px;
      animation: swim2 15s ease-in-out infinite;
      @keyframes swim2 {
        0% {background-size: 18px;}
        25% {background-size: 28px;}
        50% {background-size: 24px;}
        75% {background-size: 18px;}
        100% {background-size: 32px;}
      }
    }

    &_4 {
      line-height: 230px;
      margin-left: -10px;
      background-size: 382px;
      background-color: #6ed1cd;
      background-image: url('/assets/img/growth.svg');
    }

  }

  &__description {
    margin: 0;
    min-height: 82px;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 36px;
    color: #6ed1cd;

    &_2 {
      color: #fff;
    }

    &_3 {
      color: #8033e0;
    }

    &_4 {
      color: #6ed1cd;
    }

  }

  &__description-sub {
    margin-top: 50px;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 16px;
    color: #3b3b3d;
  }

  &__link-line {
    position: relative;
    margin-top: 25px;
    display: inline-block;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 16px;
    color: #3b3b3d;
    text-transform: uppercase;
    text-decoration: none;
    transition: padding-left ease-in-out .2s;

    &:hover {
      padding-left: 10px;
    }

    & span {
      position: absolute;
      top: 8px;
      right: -410px;
      width: 400px;
      height: 2px;
      background: #6ed1cd;

      &:before {
        content: '';
        position: absolute;
        top: -9px;
        right: 14px;
        width: 2px;
        height: 12px;
        background: #6ed1cd;
        transform: rotate(-45deg);
      }

      &:after {
        content: '';
        position: absolute;
        top: -9px;
        right: 6px;
        width: 2px;
        height: 12px;
        background: #6ed1cd;
        transform: rotate(-45deg);
      }

    }

    &_2 span {
      background: #fff !important;

      &:before {
        background: #fff !important;
      }

      &:after {
        background: #fff !important;
      }

    }

    &_3 span {
      background: #8033e0 !important;

      &:before {
        background: #8033e0 !important;
      }

      &:after {
        background: #8033e0 !important;
      }

    }

    &_4 span {
      background: #6ed1cd !important;

      &:before {
        background: #6ed1cd !important;
      }

      &:after {
        background: #6ed1cd !important;
      }

    }

  }

}
.people{
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  margin: 0 -10px;
  z-index: -1;
  background:#3b3b3d;
  &__dots{
    width: 8px;
    height: 8px;
    margin: 3px 2px;
    border-radius: 50%;
    @include breakpoint(medium){
      width: 16px;
      height: 16px;
      margin: 10px 10px;
    }
    background:#8033e0;
    &:nth-child(2n){
      transform: scale(.3);
      animation: scaleDots1 4s linear infinite;
      @keyframes scaleDots1 {
        0% {transform: scale(.3)}
        50% { transform: scale(1.2)}
        100% { transform: scale(.3)}
      }
    }
    &:nth-child(2n-1){
      transform: scale(1.2);
      animation: scaleDots 4s linear infinite;
      @keyframes scaleDots {
        0% {transform: scale(1.2)}
        50% { transform: scale(.3)}
        100% { transform: scale(1.2)}
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1024px) {

  .content-b {

    &__container {
      clip: rect(auto, auto, auto, auto);
      position: relative;
      width: 100%;
      min-height: 100vh;
      overflow: hidden;
    }

    &__wrap {
      width: 100%;

      &_fix {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
      }

    }

  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

  .content-b {

    &__box {
      max-width: 580px;
    }

    &__title {
      font-size: 157px;
    }

    &__description {
      margin-top: 15px;
      min-height: 82px;
      font-size: 36px;
      word-wrap: break-word;
    }

    &__description-sub {
      margin-top: 110px;
      font-size: 30px;
    }

    &__link-line {
      margin-top: 25px;
      font-size: 34px;

      & span {
        top: 29px;
        right: -363px;
        width: 333px;
      }

    }

  }

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .content-b {

    &__box {
      max-width: 240px;
    }

    &__title {
      font-size: 60px;
    }

    &__description {
      margin-top: 10px;
      min-height: 45px;
      font-size: 18px;
      word-wrap: break-word;
    }

    &__description-sub {
      margin-top: 55px;
      font-size: 14px;
    }

    &__link-line {
      margin-top: 10px;
      font-size: 16px;

      & span {
        top: 8px;
        right: -110px;
        width: 100px;
        height: 1px;

        &:before {
          top: -9px;
          width: 1px;
          height: 10px;
        }

        &:after {
          top: -9px;
          right: 6px;
          width: 1px;
          height: 10px;
        }

      }

    }

  }

}

#time .content-b__box{
  width: 100%;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}
