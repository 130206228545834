.chart-resources-wrap {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.chart-resources {
  position: relative;
  width: 100%;

  &__line {
    position: relative;
    width: 100%;
    height: 1px;
    margin-bottom: 56px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: attr(data-lb) '';
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100px;
      text-align: right;
      font-family: $font-family-one;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: .2px;
      color: #fff;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: calc(100% - 110px);
      height: 1px;
      background: #fff;
    }

  }

  &__bar-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 110px);
    height: calc(100% - 57px);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 50px;
  }

  &__bar-lb {
    position: relative;
    width: 50px;
    height: 0;
    background: #8033e0;

    &.animate {
      height: 100%;
    }

    &:nth-child(1) {
      height: 0;
      transition: height ease-in-out 1s;

      &.animate {
        height: 232px;
      }

    }

    &:nth-child(2) {
      height: 0;
      transition: height ease-in-out 1.2s;

      &.animate {
        height: 205px;
      }

    }

    &:nth-child(3) {
      height: 0;
      transition: height ease-in-out 1.4s;

      &.animate {
        height: 104px;
      }

    }

    &:after {
      content: attr(data-lb) '';
      position: absolute;
      bottom: -30px;
      left: 50%;
      margin-left: -50px;
      width: 100px;
      font-family: $font-family-one;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: .2px;
      color: #fff;
      text-align: center;
    }

  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1024px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

  .chart-resources-wrap {
    margin-top: 20px;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .chart-resources {

    &__line {

      &:before {
        bottom: -1px;
        left: auto;
        right: 0;
      }

      &:after {
        width: 100%;
      }

    }

    &__bar-wrap {
      left: 0;
      right: auto;
      padding: 0;
    }

    &__bar-lb {
      position: relative;
      width: 45px;
      background: #8033e0;

      &:after {
        bottom: -35px;
        margin-left: -40px;
        width: 80px;
        text-align: center;
      }

    }

  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}
