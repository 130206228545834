.page-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;
	&__body {
		flex: 1 1 auto;
		order: 0;
	}

	&__footer {
		order: 1;	
	}
}