@media screen and (min-width:0\0) { 

.header {
  display: none;

  &_unfix {
    position: relative;
  }

  &_show {
    display: block!important;
  }

  &_md-show {
    display: block;
    background: #fff;
  }

}

.content-b {

  &__container {
    position: relative;
    width: 100%;
    min-height: 100vh;
  }

  &__wrap {
    width: 100%;

    &_fix {
      position: relative;
      height: 100vh;
    }

  }

  &__title {
    background-image: none;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% 100%;
    background-clip: content-box;
    -webkit-background-clip: content-box;
    color: #6ed1cd;

    &_2 {
      background-image: none;
      color: #fff;
    }

    &_3 {
      background-image: none;
      color: #8033e0;
    }

    &_4 {
      background-image: none;
      color: #6ed1cd;
    }

  }


}


.scroll-list {

  &__item {

    &_active {

      &:before {
        content: '';
        height: 1px;
        background: #fff!important;
      }

    }

  }

}

}

/* стили только для IE10 и IE11 */ 
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
	
.header {
  display: none;

  &_unfix {
    position: relative;
  }

  &_show {
    display: block!important;
  }

  &_md-show {
    display: block;
    background: #fff;
  }

}

.content-b {

  &__container {
    position: relative;
    width: 100%;
    min-height: 100vh;
  }

  &__wrap {
    width: 100%;

    &_fix {
      position: relative;
      height: 100vh;
    }

  }

  &__title {
    background-image: none;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% 100%;
    background-clip: content-box;
    -webkit-background-clip: content-box;
    color: #6ed1cd;

    &_2 {
      background-image: none;
      color: #fff;
    }

    &_3 {
      background-image: none;
      color: #8033e0;
    }

    &_4 {
      background-image: none;
      color: #6ed1cd;
    }

  }


}


.scroll-list {

  &__item {

    &_active {

      &:before {
        content: '';
        height: 1px;
        background: #fff!important;
      }

    }

  }

}

}

