.footer {
  position: relative;
  width: 100%;
  height: 100vh;
  outline: 0;

  &__bg {
    position: absolute;
    left: 0;
    right: 45vw;
    width: 45vw;
    height: 100%;
    background: url("/assets/img/sea.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &__video{
    position: relative;
    height: 100%;
  }
  &__container {
    clip: rect(auto,auto,auto,auto);
    position: absolute;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }

  &__row {
    height: 100vh;
  }

}

.footer-content {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  &__box{
    // padding: 153px 20px 0 116px;
  }

  &__title {
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 61px;
    text-transform: uppercase;
    line-height: 80px;
    letter-spacing: .2px;
    color: #3b3b3d;
  }

  &__btn-wrap {
    margin: 30px 0 0 11px;
  }

  &__contact-wrap {
    margin-top: 100px;
  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1024px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

  .footer-content {
    padding: 300px 20px 0 116px;

    &__title {
      font-size: 31px;
      line-height: 60px;
    }

  }

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .footer-content {
    padding: 100px 20px 0 25px;

    &__title {
      font-size: 16px;
      line-height: 32px;
    }

    &__btn-wrap {
      margin: 20px 0 0 11px;
    }

    &__contact-wrap {
      margin-top: 50px;
    }

  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}
