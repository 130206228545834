.company{
	&__section{
		&--multicolor{
			background-color: #8033e0;
			padding-bottom: 100px;
			animation: multicolor linear 8s  1s infinite;
			@keyframes multicolor{
				0%{background-color: #8033e0;}
				33%{background-color: #6ed1cd;}
				66%{background-color: #6ed1cd;}
				100%{background-color: #8033e0;}
			}
		}
		&--modals{
			position: relative;
			background-color: #f4f6f6;
		}
		&--slider{
			position: relative;
		}
	}
	&__overlay-box{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
	}
	&__title{
		font-family: $font-family-one;
		font-size: 60px;
		font-weight: 800;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 1.2px;
		text-align: left;
		color: #fcfcfc;
		margin: 0;
		margin-bottom: 42px;
	}
	&__text{
		font-family: $font-family-one;
		font-size: 14px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.3px;
		text-align: left;
		color: #fcfcfc;
	}
	&__big-logo{
		text-align: center;
		svg{
			max-width: 187px;
			max-height: 225px;
			display: inline-block;
			width: 100%;
			margin-bottom: 28px;
			fill: #6ed1cd;
			animation: multicolorSvg linear 8s  1s infinite;
			@keyframes multicolorSvg{
				0%{fill: #6ed1cd;}
				33%{fill: #8033e0;}
				66%{fill: #8033e0;}
				100%{fill: #6ed1cd;}
			}
			
		}
	}
	&__title{
		&2{
			font-family: $font-family-one;
			font-size: 18px;
			font-weight: 800;
			letter-spacing: 1.1px;
			text-align: left;
			color: #3a3a3b;
			line-height: 54px;
			margin: 0;
		}
		&3{
			font-family: $font-family-one;
			font-size: 16px;
			font-weight: 800;
			letter-spacing: 1px;
			text-align: left;
			color: #6ed1cd;
			line-height: 48px;
			margin: 0;
		}
	}
	&__item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 42px;
		cursor: pointer;
		height: 69px;
		span{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 26px;
			height: 26px;
			border-radius: 50%;
			&:before{
				content: '+';
				font-family: $font-family-one;
				font-size: 26px;
				font-weight: 300;
				letter-spacing: 1px;
				line-height: 22px;
				text-align: center;
				color: $white;
			}
		}
		&--1{
			background-color: $white;
		}
		&--2{
			background-color: #6ed1cd;
			.company__title3{
				color: $white;
			}
			span{
				background-color: #8033e0;
			}
		}
		&--3{
			background:transparent url('/assets/img/company-slider/overlay2.svg') center center no-repeat;
			background-size: cover;
			.company__title3{
				color: #8033e0;
			}
			span{
				background-color: #6ed1cd;
			}
		}
		&--4{
			background-color: #8033e0;
			.company__title3{
				color: #6ed1cd;
			}
			span{
				background-color: #3a3a3b;
			}
		}
		&--5{
			background-color: #e0e6ee;
			.company__title3{
				color: #3a3a3b;
			}
			span{
				background-color: #8033e0;
				&:before{
					color: #6ed1cd;
				}
			}
		}
		&--6{
			
			background-color: #3a3a3b;
			.company__title3{
				color: $white;
			}
			span{
				background-color: $white;
				&:before{
					color: #6ed1cd;
				}
			}
		}

	}
	&__order{
		&-1{
			order: 2;
		}
		&-2{
			order: 1;
		}
	}
	&__title-box{
		margin-left: -10px;
		margin-bottom: 58px;
		width: calc(100% + 20px);
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $white;
		.company__title{
			font-size: 24px;
			font-weight: 800;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.25;
			letter-spacing: 0.5px;
			text-align: center;
			color: #000000;
			margin-bottom: 0;
		}
	}
	@include breakpoint(medium){
		&__section{
			&--multicolor{
				padding: 82px 0;
				padding-bottom: 174px;
			}
			&--modals{
				padding: 62px 0;
			}
		}
		&__title{
			&2{
				font-size: 54px;
			}
			&3{
				font-size: 48px;
			}
		}
		&__big-logo{
			svg{
				max-width: 286px;
				max-height: 344px;
			}
		}
		&__item{
			span{
				width: 42px;
				height: 42px;
				&:before{
					font-size: 48px;
				}
			}
			&--1{
				height: 119px;
			}
			&--2{
				height: 130px;
			}
			&--3{
				height: 133px;
			}
			&--4{
				height: 263px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.company__title3{
					color: #6ed1cd;
					margin-bottom: 43px;
				}
			}
			&--5{
				height: 168px;
			}
			&--6{
				height: 168px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.company__title3{
					margin-bottom: 25px;
				}
			}
		}
	}
	@include breakpoint(large){
		&__order{
			&-1{
				order: 1;
			}
			&-2{
				order: 2;
			}
		}
		&__big-logo{
			svg{
				margin-bottom: 0;
			}
		}
	}
}

.company-modals{
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 1222px;
	background: #e0e6ee;
	overflow: auto;
	&__item{
		display: none;
		padding: 15px;
		min-height: 100%;
		&[data-modals="1"]{
			background-color: #6ed1cd;
			.company-modals__close{&:before,&:after{background-color: #8033e0;}}
			.company-modals__sharp{color: #8033e0;}
			.company-modals__title, .company-modals__text{color:$white;}
		}
		&[data-modals="2"]{
			background-color: $white;
			.company-modals__close{&:before,&:after{background-color: #6ed1cd;}}
			.company-modals__sharp{color: #6ed1cd;}
			.company-modals__title, .company-modals__text{color:#8033e0;}
		}
		&[data-modals="3"]{
			background-color: #8033e0;
			.company-modals__close{&:before,&:after{background-color: #3a3a3b;}}
			.company-modals__sharp{color: #3a3a3b;}
			.company-modals__title, .company-modals__text{color: #6ed1cd;}
		}
		&[data-modals="4"]{
			background-color: #e0e6ee;
			.company-modals__close{&:before,&:after{background-color: #8033e0;}}
			.company-modals__sharp{color: #8033e0;}
		}
		&[data-modals="5"]{
			background-color: #3a3a3b;
			.company-modals__close{&:before,&:after{background-color: $white;}}
			.company-modals__sharp{color: $white;}
			.company-modals__title, .company-modals__text{color:$white;}
		}
	}
	&__close{
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 20px;
		right: 20px;
		width: 34px;
		height: 34px;
		&:before{
			position: absolute;
			content: '';
			display: block;
			width: 100%;
			height: 3px;
			transform: rotate(45deg);
			background-color: #8033e0;
		}
		&:after{
			position: absolute;
			content: '';
			display: block;
			width: 100%;
			height: 3px;
			transform: rotate(135deg);
			background-color: #8033e0;
		}
	}
	&__sharp{
		font-family: $font-family-one;
		font-size: 36px;
		line-height: 22px;
		font-weight: 300;
		letter-spacing: 1.7px;
		text-align: left;
		color: #8033e0;
		margin-bottom: 16px;
	}
	&__title{
		font-family: $font-family-one;
		font-size: 18px;
		line-height: 18px;
		font-weight: 800;
		letter-spacing: 1.2px;
		text-align: left;
		color: #3a3a3b;
		margin-bottom: 20px;

	}
	&__text{
		font-family: $font-family-one;
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 0.4px;
		text-align: left;
		color: #3a3a3b;
		max-width: 640px;
	}
	@include breakpoint(medium){
		top: 62px;
		bottom: 62px;
		&__item{
			padding: 74px;
		}
		&__sharp{
			font-size: 85px;
			line-height: 60px;
			margin-bottom: 46px;
		}
		&__title{
			font-size: 60px;
			line-height: 60px;
			margin-bottom: 37px;
		}
		&__text{
			font-size: 18px;
		}
	}
}


.company-slider{
	transition: background-color .3s ease-in-out;
	&[data-color-slide="0"]{

		.company-slider__before-title,.company-slider__text-title{
			color: #8033E0;
		}
		.slick-arrow{
			border: solid 2px #8033E0;
			&:before, &:after{
				background-color: #8033E0;
			}
			&:hover{
				background-color: #8033E0;
			}
		}
	}
	&[data-color-slide="1"]{
		.company-slider__before-title,.company-slider__text-title{
			color:#6ed1cd;
		}
		.slick-arrow{
			border: solid 2px#6ed1cd;
			&:before, &:after{
				background-color:#6ed1cd;
			}
			&:hover{
				background-color:#6ed1cd;
			}
		}
	}
	&[data-color-slide="2"]{
		.company-slider__before-title,.company-slider__text-title{
			color: #6ed1cd;
		}
		.slick-arrow{
			border: solid 2px #6ed1cd;
			&:before, &:after{
				background-color: #6ed1cd;
			}
			&:hover{
				background-color: #6ed1cd;
			}
		}
	}
	&[data-color-slide="3"]{
		.company-slider__before-title,.company-slider__text-title{
			color: #8033E0;
		}
		.slick-arrow{
			border: solid 2px #8033E0;
			&:before, &:after{
				background-color: #8033E0;
			}
			&:hover{
				background-color: #8033E0;
			}
		}
	}
	&__overlay{
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background:transparent  center center repeat;
		background-size: cover;
		&[data-overlay-slide="0"]{display: block;background-color: #8033E0;background-image: url('/assets/img/company-slider/overlay1.svg')}
		&[data-overlay-slide="1"]{background-color: #3a3a3b;background-image: url('/assets/img/company-slider/overlay2.svg')}
		&[data-overlay-slide="2"]{background-image: url('/assets/img/company-slider/overlay3.svg')}
		&[data-overlay-slide="3"]{background-color: #6ed1cd;background-image: url('/assets/img/company-slider/overlay4.svg')}
	}
	&__item{
		position: relative;
	}
	&__wrapper{
		@include flex-grid-row;
		background-color: $white;
	}
	&__column{
		min-height: 440px;
		@include flex-grid-column(100%);
	}
	&__before-title{
		font-family: $font-family-one;
		font-size: 18px;
		font-weight: 800;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.4px;
		text-align: left;
		color: #6ed1cd;
	}
	&__title{
		font-family: $font-family-one;
		font-size: 24px;
		font-weight: 800;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 1.2px;
		text-align: left;
		color: #3a3a3b;
	}
	&__box{

	}
	&__rank{
		max-width: 100%;
		margin-bottom: 24px;
	}
	&__box-column{
		max-width: 100%;
		margin-bottom: 24px;
	}
	&__text-title{
		font-family: $font-family-one;
		font-size: 16px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.5px;
		text-align: left;
		color: #6ed1cd;
		margin-bottom: 18px;
	}
	&__text{
		display: block;
		font-family: $font-family-one;
		font-size: 14px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.3px;
		text-align: left;
		color: #3a3a3b;
		min-height: 128px;
	}
	&__img{
		width: calc(100% + 1.750rem);
		height: 100% ;
		min-height: 198px;
		margin:  0 -0.9375rem;
		background:transparent  center center no-repeat;
		background-size: contain;
	}
	&__container{
		padding-top: 35px;
		padding-left: 24px;
		padding-right: 50px;
	}
	.slick-arrow{
		position: absolute;
		bottom: 20px;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		border: solid 2px #6ed1cd;
		font-size: 0;
		z-index: 10;
		&:hover{
			background-color: #6ed1cd;
			&:before, &:after{
				background-color: $white;
			}
		}
		&:before{
			content: '';
			position: absolute;
			top: 10px;
			left: 11px;
			width: 13px;
			height: 2px;
			background-color: #6ed1cd;
			transform: rotate(45deg);
		}
		&:after{
			content: '';
			position: absolute;
			top: 18px;
			left: 11px;
			width: 13px;
			height: 2px;
			background-color: #6ed1cd;
			transform: rotate(135deg);
		}
	}
	.slick-next{
		right: 20px;
	}
	.slick-prev{
		right: 80px;
		&:before{
			left: 9px;
			transform: rotate(-45deg);
		}
		&:after{
			left: 9px;
			transform: rotate(-135deg);
		}
	}
	@include breakpoint(medium){
		&__item{
			padding: 85px 0;
		}
		&__column{
			&:first-child{
				@include flex-grid-column(5/12);
			}
			&:last-child{
				@include flex-grid-column(7/12);
			}
		}
		
		&__title{
			font-size: 60px;
		}
		&__text-title{
			font-size: 24px;
		}
		&__img{
			background-size: cover;
		}
		.slick-arrow{
			top: 570px;
			bottom: auto;
		}
	}
	@include breakpoint(large){
		&__column{
			&:first-child{
				@include flex-grid-column(3/12);
			}
			&:last-child{
				@include flex-grid-column(9/12);
			}
		}
		&__box{
			display: flex;
			margin: 0 -30px;
		}
		&__box-column{
			margin: 0;
			padding: 0 30px;
			max-width: 50%;
		}
		.slick-next{
			right: calc(((100vw - 76.375rem)/2) + 30px);
		}
		.slick-prev{
			right: calc(((100vw - 76.375rem)/2) + 90px);
		}
	}
}
.company-social{
	margin: 21px -15px;

	&__item{
		display: inline-block;
		font-family: $font-family-one;
		font-size: 18px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.5px;
		text-align: left;
		color: #3a3a3b;
		padding: 0 15px;
	}
	@include breakpoint(medium){
		&__item{
			font-size: 24px;
		}
	} 
}


.company-slider1{
	&__item{
		font-size: 30px;
		color: red;
		margin: 30px;
	}
}