@import "normalize.css";


* {
  box-sizing: border-box;
}

a:hover, a:focus {
  color: inherit;
}

html {
  height: 100%;
}

body {
  background-color:$white;
  font-family: $font-family-one;

  &.toggled {
    overflow: hidden!important;
  }

}


#mask {
  z-index: 0;
  height: 100%;
  overflow: hidden;
}

#mask-main {
  width: 100vw;
  height: 100vh;

  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition-property: top,bottom;
  -webkit-transition-duration: .5s;
  -moz-property: top,bottom;
  -moz-duration: .5s;
  -ms-property: top,bottom;
  -ms-duration: .5s;
  -o-property: top,bottom;
  -o-duration: .5s;
  transition-property: top,bottom;
  transition-duration: .5s;
}

.svg-container{
  display: none;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1024px) {

  #mask {
    overflow: auto;
  }

  #mask-main {
    height: auto;
  }

}
#centralSection{
  background-color: #71cac6;
  .scroll-list__item_active:before{
    background:$white;
  }
  .content-b{
    &__big-logo{
      width: 153px;
      height: 227px;
      margin: 0 auto;
      margin-top: 146px;

      @include breakpoint(medium){
        width: 233px;
        height: 327px;
        margin: 0 auto;
        margin-top: 31vh;
      }
      @include breakpoint(medium){
        margin-top: 11vh;
      }
    }
    &__description{
      min-height: 0;
      line-height: 39px;
      margin-top: -30px;
      font-size: 28px;
      text-align: center;
      color: $white;
    }
    &__description-sub{
      max-width: 440px;
      font-family: Gilroy;
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: center;
      color: #fcfcfc;
      margin-top: 20px;
    }
    &__wrap{
      &:before{
        display: none;
        content: '';
        position: absolute;
        width: 132px;
        height: 175px;
        top: 111px;
        left: 0;
        background:transparent url('/assets/img/Left.svg') center center no-repeat;
      }
      &:after{
        display: none;
        content: '';
        position: absolute;
        width: 78px;
        height: 111px;
        right: 0;
        bottom: 0;
        background:transparent url('/assets/img/Right.svg') center center no-repeat;
      }
      @include breakpoint(medium){
        &:before{
          display: block;
          top: 123px;
          width: 351px;
          height: 464px;
        }
        &:after{
          display: block;
          width: 231px;
          height: 289px;
        }
      }
      @include breakpoint(large){
        &:before{
          width: 483px;
          height: 697px;
          top: 103px;
          left: 0;
        }
        &:after{
          width: 353px;
          height: 442px;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}

#canvas-time{
  width: calc(100% - 4px);
  height: calc(100% - 0px);
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 2px;
  bottom: 0px;
  right: 2px;
  background: #3b3b3d;
  @include breakpoint(medium){
    height: calc(100% - 4px);
    top: 2px;
    bottom: 2px;
  }
  @include breakpoint(large){
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    z-index: -1;
    position: absolute;
    top: 15px;
    left: 15px;
    bottom: 15px;
    right: 15px;
  }
}