.contact {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 0;
  background: #fff;
  transition: all .3s ease-in-out;

  &.toggled {
    visibility: visible;
    opacity: 1;
    height: 100vh;
    overflow: hidden;
  }

  &__sub-title {
    position: fixed;
    left: -250px;
    bottom: 222px;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 112px;
    color: #3b3b3d;
    text-transform: uppercase;
    letter-spacing: .2px;
    transform: rotate(-90deg);
  }

}

.contact-list {
  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;

  &_footer {
    position: relative;
    height: auto;
    padding-top: 0!important;
    padding-left: 0!important;
  }

  &__item {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

  }

  &__link {
    position: relative;
    display: inline-block;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 14px;
    letter-spacing: .2px;
    color: #3b3b3d;
    text-decoration: none;

    &_phone {
      padding-left: 60px;

      &:before {
        content: '';
        position: absolute;
        top: -5px;
        left: 0;
        width: 34px;
        height: 26px;
        background: url("/assets/img/contact/phone.svg") no-repeat;
        background-size: 34px 26px;
      }

    }

    &_mail {
      padding-left: 60px;

      &:before {
        content: '';
        position: absolute;
        top: -5px;
        left: 0;
        width: 34px;
        height: 26px;
        background: url("/assets/img/contact/mail.svg") no-repeat;
        background-size: 34px 26px;
      }

    }

    &_pin {
      padding-left: 60px;

      &:before {
        content: '';
        position: absolute;
        top: -5px;
        left: 0;
        width: 34px;
        height: 26px;
        background: url("/assets/img/contact/pin.svg") no-repeat;
        background-size: 34px 26px;
      }

    }

  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {

  .contact-list {
    position: relative;
    padding-top: 200px;
    padding-left: 90px;
    padding-bottom: 100px;
    height: auto;
  }

}


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

  .contact-list {
    padding-left: 90px;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width : 760px) {

  .contact {

    &__sub-title {
      left: 50%;
      margin-left: -141px;
      bottom: -13px;
      font-size: 54px;
      transform: rotate(0deg);
    }

  }

  .contact-list {
    padding-left: 0;

    &__link {

      &_phone {
        padding-left: 50px;
      }

      &_mail {
        padding-left: 50px;
      }

      &_pin {
        padding-left: 50px;
      }

    }

  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}

