.main {
  -webkit-box-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {

  .main {

  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

  .main {

  }

}

/* Small Devices, Tablets */
@media only screen and (max-width : 760px) {

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}
