.chart-money-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.chart-money {
  position: relative;
  width: 295px;
  height: 295px;
  overflow: hidden;
  border-radius: 50%;
  background: #8033e0;

  &__lb-1 {
    position: absolute;
    top: 50px;
    left: 50%;
    margin-left: 30px;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: .2px;
    color: #fff;
  }

  &__lb-2 {
    position: absolute;
    top: 140px;
    left: 50%;
    margin-left: 60px;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: .2px;
    color: #fff;
  }

  &__lb-3 {
    position: absolute;
    top: 150px;
    left: 50%;
    margin-left: -100px;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: .2px;
    color: #fff;
  }

  &__lbd-1 {
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: 100px;
    display: flex;
    flex-direction: row;
    font-family: $font-family-one;


    & span:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 85px;
      height: 85px;
      font-weight: 800;
      font-size: 36px;
      text-transform: uppercase;
      letter-spacing: .2px;
      color: #fff;
      background: #3b3b3d;
    }

    & span:last-child {
      display: flex;
      align-items: center;
      width: 150px;
      padding-left: 10px;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: .2px;
      color: #3b3b3d;
    }

  }

  &__lbd-2 {
    position: absolute;
    top: 120px;
    left: 50%;
    margin-left: 190px;
    display: flex;
    flex-direction: column;
    font-family: $font-family-one;


    & span:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 85px;
      height: 85px;
      font-weight: 800;
      font-size: 36px;
      text-transform: uppercase;
      letter-spacing: .2px;
      color: #fff;
      background: #6ed1cd;
    }

    & span:last-child {
      display: flex;
      align-items: center;
      width: 150px;
      padding-top: 10px;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: .2px;
      color: #3b3b3d;
    }

  }

  &__lbd-3 {
    position: absolute;
    top: 250px;
    left: 50%;
    margin-left: -230px;
    display: flex;
    flex-direction: column;
    font-family: $font-family-one;


    & span:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 85px;
      height: 85px;
      font-weight: 800;
      font-size: 36px;
      text-transform: uppercase;
      letter-spacing: .2px;
      color: #fff;
      background: #8033e0;
    }

    & span:last-child {
      display: flex;
      align-items: center;
      width: 150px;
      padding-top: 10px;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: .2px;
      color: #3b3b3d;
    }

  }

  &__circle {
    height: 100%;
    width: 100%;
    background: #3b3b3d;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    transform-origin: center bottom;

    &_1 {
      transform: rotate(-126deg);
      background: #3b3b3d;
    }


    &_2 {
      transform: rotate(-105deg);
      background: #6ed1cd;
    }

    &_3 {
      border-radius: 51% 51% 0 0 / 101% 101% 0 0;
      transform: rotate(0deg);
      background: #8033e0;
    }

  }

  &__triangle {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 50%;
    height: 50%;
    width: 100%;
    transform-origin: center bottom;
    overflow: hidden;
    //transform: rotate(0);

    &_1 {
      transform: rotate(96deg);
    }

    &_2 {
      transform: rotate(150deg);
    }

    &_3 {
      transform: rotate(225deg);
    }

  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1024px) {

  .chart-money-wrap {
    height: 420px;
    margin: 50px 0;
  }

  .chart-money {

    &__lbd-1 {
      top: -40px;
      margin-left: 0px;
    }

    &__lbd-2 {
      top: 315px;
      margin-left: 115px;
    }

    &__lbd-3 {
      top: 250px;
      margin-left: -240px;
    }

  }

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .chart-money-wrap {
    height: 600px;
    align-items: flex-start;
  }


  .chart-money {
    width: 270px;
    height: 270px;

    &__lbd-1 {
      top: 300px;
      left: 0;
      margin-left: 0;

      & span:first-child {
        font-size: 24px;
      }

    }

    &__lbd-2 {
      top: 392px;
      left: 0;
      margin-left: 0;
      flex-direction: row;

      & span:first-child {
        font-size: 24px;
      }

      & span:last-child {
        padding-top: 0;
        padding-left: 10px;
      }

    }

    &__lbd-3 {
      top: 485px;
      left: 0;
      margin-left: 0;
      flex-direction: row;

      & span:first-child {
        font-size: 24px;
      }

      & span:last-child {
        padding-top: 0;
        padding-left: 10px;
      }

    }

  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}
