.modal {
  display: none;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.modal__container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 100;
  background-color: #fff;
  padding: 40px 55px;
  max-width: 530px;
  max-height: 100vh;
  border-radius: 0;
  overflow-y: auto;
  box-sizing: border-box;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin: 0 0 20px 0;
  font-family: $font-family-one;
  font-weight: 800;
  font-size: 24px;
  color: #3b3b3d;
  text-transform: uppercase;
  letter-spacing: .2px;
}

.modal__close {
  background: transparent;
  border: 0;
  outline: none;
  margin: 0 -5px 15px 0;
  cursor: pointer;
}

.modal__header .modal__close:before { content: "\2715"; }

.modal__content {

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .modal__title {
    margin: 0 0 20px 0;
    font-size: 18px;
  }

  .modal__container {
    padding: 25px;
  }

  .modal__close {
    top: 26px;
    right: 20px;
  }



}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
