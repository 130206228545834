.form {

  &__wrap {

  }

  &__group {
    display: flex;
    flex-direction: column;
  }

  &__label {
    position: relative;
    margin-bottom: 12px;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 14px;
    color: #6ed1cd;
    text-transform: uppercase;
    letter-spacing: .2px;
    cursor: pointer;

    &_2 {
      color: #8033e0;
    }

  }

  &__label-text {
    display: inline-block;
    position: relative;
    margin-bottom: 24px;
    margin-right: 15px;
    padding-left: 35px;
    color: #3b3b3d;
    cursor: pointer;

    & a {
      color: #3b3b3d;
    }

  }

  &__control {
    margin-bottom: 24px;
    padding: 10px;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 16px;
    color: #3a3a3b;
    line-height: 18px;
    letter-spacing: .2px;
    border: 1px solid #e0e6ee;
    border-radius: 0;
    outline: none;
    box-shadow: none!important;
    -webkit-appearance: none;
    transition: all .2s ease-in-out;

    &:hover {
      border-color: #6ed1cd;
    }

    &:focus {
      border-color: #6ed1cd;
    }

    &_2 {

      &:hover {
        border-color: #8033e0;
      }

      &:focus {
        border-color: #8033e0;
      }

    }

    &_border-bottom {
      padding: 0;
      width: 400px;
      background: transparent;
      border: none;
      border-bottom: 1px solid #3b3b3d;
    }

  }

}

/*Check box*/

input[type="checkbox"], input[type="radio"] {
  position: absolute;
  right: 9000px;
}

input[type="checkbox"] + .form__label-text:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 0;
  background-color: #ffffff;
  border: solid 1px #6ed1cd;
}
input[type="checkbox"] + .form__label-text:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 0;
  background-color: #6ed1cd;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

input[type="checkbox"] + .form__label-text_2:before {
  border: solid 1px #8033e0;
}
input[type="checkbox"] + .form__label-text_2:after {
  background-color: #8033e0;
}

input[type="checkbox"]:checked + .form__label-text:after {
  opacity: 1;
}

input[type="checkbox"]:checked + .form__label-text_2:before {
  opacity: 1;
}

input[type="checkbox"]:disabled + .form__label-text {
  color: #aaa;
}

input[type="checkbox"]:disabled + .form__label-text:before {
  background-image: none;
  border-radius: 4px;
  background-color: #dedede;
  border-color: #dedede;
}


/*Radio box*/

input[type="radio"] + .form__label-text:before {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 0;
  background-color: #ffffff;
  border: solid 1px #6ed1cd;
}

input[type="radio"] + .form__label-text_2:before {
  border: solid 1px #8033e0;
}

input[type="radio"]:checked + .form__label-text:before {
  content: '';
  background-image: url("/assets/img/check.svg");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 50%;
}

input[type="radio"]:checked + .form__label-text_2:before {
  background-image: url("/assets/img/check_2.svg");
}

input[type="radio"]:disabled + .form__label-text {
  color: #aaa;
}

input[type="radio"]:disabled + .form__label-text:before {
  background-image: none;
  border-radius: 4px;
  background-color: #dedede;
  border-color: #dedede;
}

.form-success {
  padding: 50px;
  height: 462px;

  &__title {
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    color: #6ed1cd;
    text-transform: uppercase;
    letter-spacing: .2px;
  }

  &__description {
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #3b3b3d;
    text-transform: uppercase;
    letter-spacing: .2px;
  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .form-success {
    padding: 25px;

    &__title {
      font-size: 16px;
      line-height: 24px;
    }

    &__description {
      font-size: 14px;
      line-height: 18px;
    }

  }

  .form {

    &__control {
      margin-bottom: 24px;
      padding: 10px;
      font-family: $font-family-one;
      font-weight: 300;
      font-size: 16px;
      color: #3a3a3b;
      line-height: 18px;
      letter-spacing: .2px;
      border: 1px solid #e0e6ee;
      outline: none;
      transition: all .2s ease-in-out;

      &:hover {
        border-color: #6ed1cd;
      }

      &:focus {
        border-color: #6ed1cd;
      }

      &_2 {

        &:hover {
          border-color: #8033e0;
        }

        &:focus {
          border-color: #8033e0;
        }

      }

      &_border-bottom {
        padding: 0;
        width: 150px;
        background: transparent;
        border: none;
        border-bottom: 1px solid #3b3b3d;
      }

    }

  }

}
