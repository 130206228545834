.page {
  margin-top: 20px;

  &__title {
    margin: 0;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 48px;
    color: #0d0d0e;
    text-transform: uppercase;
    letter-spacing: .2px;
    text-align: center;
  }

}

.page-container {
  position: relative;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.page-banner {
  position: relative;
  margin-top: 25px;
  height: 830px;
  background-repeat: repeat;
  background-image: url('/assets/img/money/bg-3.png');
  background-size: 171px 171px;
  width: 100%;
  overflow: hidden;
  &_1{
    background-image: url('/assets/img/linesvlad.svg');
    background-repeat: repeat;
    background-color: $white;
    background-size: 852px;
    background-position: 0px 100%;
    color: transparent;
    animation: swim2 20s linear infinite;
    @keyframes swim2 {
      from { background-position: 0px 100%}
      to { background-position: -852px 100%}
    }
  }
  &_2 {
    background:#3b3b3d;
  }

  &_3 {
    background-image: none;
    // background-size: 25px 25px;
    // background-color: #8033e0;
  }

  &_4 {
    background-color: #6ed1cd;
    background-image: url('/assets/img/growth.svg');
    background-repeat: repeat;
    background-size: 462px;
    background-position: 0px 100%;
    color: transparent;
    animation: swim1 20s linear infinite;
    @keyframes swim1 {
      from { background-position: 0px 100%}
      to { background-position: -462px 100%}
    }
  }

  &__text-box-1 {
    position: absolute;
    z-index: 1;
    top: 126px;
    left: 50%;
    margin-left: -400px;
    width: 400px;
    background: #fff;

    &_1 {
      padding: 100px;
    }

    &_2 {
      padding: 100px 50px;
    }

    &_3 {
      padding: 60px 45px 80px;
      background: #3b3b3d;
      z-index: 1;
    }

    &_4 {
      padding: 100px 50px;
      z-index: 1;
    }

  }

  &__text-box-2 {
    position: absolute;
    z-index: 1;
    top: 275px;
    left: 50%;
    margin-left: -120px;
    width: 620px;
    background: #fff;

    &_1 {
      padding: 60px;
    }

    &_2 {
      top: 300px;
      margin-left: -70px;
      width: 590px;
      padding: 60px 60px 60px 60px;
    }

  }

  &__title {
    margin: 0;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 36px;
    color: #3b3b3d;
    line-height: 48px;
    text-transform: uppercase;
    letter-spacing: .2px;

    &_2 {
      color: #fff;
    }

  }

  &__description {
    margin: 0;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 24px;
    color: #3b3b3d;
    line-height: 36px;
    letter-spacing: .2px;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;

    /* Medium Devices, Desktops */
    @media only screen and (max-width: 1500px) {
      width: auto;
      height: 100%;
    }

  }

}
#page-banner-canvas{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.page-banner__people{
  position: absolute;
  top: -100px;
  left: -100px;
  bottom: -100px;
  right: -100px;
  .people{
    background-color: #8033e0;
    &__dots{
      background-color: $white;
    }
  }
}
.page-banner-money {
  position: relative;
  height: 690px;
  background: #6ed1cd;

  &__text-box-1 {
    position: absolute;
    top: 165px;
    left: 50%;
    margin-left: -475px;
    width: 345px;
    height: 326px;
    background-image: url('/assets/img/linesvlad2.svg');
    background-repeat: repeat;
    background-color: transparent;
    background-size: 852px;
    background-position: 0px 100%;
    color: transparent;
    animation: swim2 20s linear infinite;
    @keyframes swim2 {
      from { background-position: 0px 100%}
      to { background-position: -852px 100%}
    }
  }

  &__text-box-2 {
    position: absolute;
    top: 165px;
    left: 50%;
    margin-left: -50px;
    width: 560px;
  }

  &__title {
    position: absolute;
    top: 140px;
    left: 90px;
    display: inline-block;
    margin: 0;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 48px;
    color: #3b3b3d;
    text-transform: uppercase;
    letter-spacing: .2px;
    transform: rotate(-90deg);
  }

  &__description {
    margin: 0;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 24px;
    color: #fff;
    line-height: 36px;
    letter-spacing: .2px;
  }

}

.page-banner-time {
  position: relative;
  height: 690px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 50%;
    height: inherit;
    background: #6ed1cd;
  }

  &__circle-wrap {
    padding: 100px 0;
  }

  &__circle {
    width: 490px;
    height: 490px;
    border-radius: 50%;
    background-color: #3b3b3d;
    background-image: url("/assets/img/time/bg-3.png");
    background-size: 1001px 1001px;
  }

  &__description-wrap {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 90px;
  }

  &__description {
    margin: 0;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 24px;
    color: #3b3b3d;
    line-height: 36px;
    letter-spacing: .2px;
  }

}

.page-banner-resources {
  position: relative;
  height: 690px;
  background: #3b3b3d;

  display: flex;
  align-items: center;
  justify-content: center;

  &__description {
    max-width: 840px;
    margin: 0;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 24px;
    color: #fff;
    line-height: 36px;
    letter-spacing: .2px;
  }

}

.page-banner-growth {
  position: relative;
  height: 690px;
  background: #8033e0;

  &__text-box-1 {
    position: absolute;
    top: 190px;
    left: 50%;
    margin-left: -475px;
    width: 290px;
    height: 290px;
    background-image: url('/assets/img/growth2.svg');
    background-repeat: repeat;
    background-color: transparent;
    background-size: 462px;
    background-position: 0px 100%;
    color: transparent;
    animation: swim2 20s linear infinite;
    @keyframes swim2 {
      from { background-position: 0px 100%}
      to { background-position: -462px 100%}
    }
  }

  &__text-box-2 {
    position: absolute;
    top: 190px;
    left: 50%;
    margin-left: -100px;
    width: 590px;
    height: 290px;
    display: flex;
    align-items: center;
  }

  &__title {
    position: absolute;
    top: 140px;
    left: 90px;
    display: inline-block;
    margin: 0;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 48px;
    color: #3b3b3d;
    text-transform: uppercase;
    letter-spacing: .2px;
    transform: rotate(-90deg);
  }

  &__description {
    margin: 0;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 24px;
    color: #fff;
    line-height: 36px;
    letter-spacing: .2px;
  }

}

.page-service {
  padding: 30px 0 60px;
  $g: &;

  &_2 {
    padding-top: 75px;

    #{$g}__title {
      color: #3b3b3d;
    }

  }

  &_3 {

    #{$g}__title {
      color: #8033e0;
    }

  }

  &_4 {
    padding-top: 75px;

    #{$g}__title {
      color: #8033e0;
    }

  }

  &__title {
    margin: 0;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 36px;
    color: #6ed1cd;
    line-height: 48px;
    text-transform: uppercase;
    letter-spacing: .2px;
  }

  &__btn-wrap {
    margin-top: 50px;
  }

  &__btn {
    position: relative;
    display: inline-block;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 24px;
    color: #3b3b3d;
    text-transform: uppercase;
    text-decoration: none;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: padding-left ease-in-out .2s;

    &:hover {
      padding-left: 10px;
    }


    & span {
      position: absolute;
      top: 14px;
      right: -410px;
      width: 400px;
      height: 2px;
      background: #6ed1cd;

      &:before {
        content: '';
        position: absolute;
        top: -9px;
        right: 14px;
        width: 2px;
        height: 12px;
        background: #6ed1cd;
        transform: rotate(-45deg);
      }

      &:after {
        content: '';
        position: absolute;
        top: -9px;
        right: 6px;
        width: 2px;
        height: 12px;
        background: #6ed1cd;
        transform: rotate(-45deg);
      }

    }

    &_2 {

      & span {
        background: #fff !important;

        &:before {
          background: #fff !important;
        }

        &:after {
          background: #fff !important;
        }

      }

    }

    &_3 {
      color: #8033e0;

      span {
        background: #8033e0 !important;

        &:before {
          background: #8033e0 !important;
        }

        &:after {
          background: #8033e0 !important;
        }

      }

    }
  }

}

.page-service-list {
  display: flex;
  flex-wrap: wrap;
  margin: 40px -55px 0;

  $g: &;

  &_2 {

    #{$g}__link {
      border-color: #3b3b3d;

      //&:hover {
      //  background: #3b3b3d;
      //}

    }

  }

  &_3 {

    #{$g}__link {
      border-color: #8033e0;

      //&:hover {
      //  background: #8033e0;
      //}

    }

  }

  &__item {
    width: 50%;
    padding: 0 55px;
  }

  &__link {
    display: block;
    min-height: 240px;
    margin: -1px 0;
    padding: 45px 80px 45px 45px;
    text-decoration: none;
    border-color: #6ed1cd;
    border-style: solid none solid none;
    border-width: 2px;
    transition: all .2s ease-in-out;

    //&:hover {
    //  background: #6ed1cd;
    //
    //  & h3 {
    //    color: #fff;
    //  }
    //
    //  & ul li {
    //    color: #fff;
    //  }
    //
    //}

    & h3 {
      display: block;
      font-family: $font-family-one;
      font-weight: 800;
      font-size: 24px;
      color: #272727;
      line-height: 24px;
      letter-spacing: .2px;
      transition: all .2s ease-in-out;
    }

    & ul {
      margin: 20px 0 0 0;
      padding: 0;
      list-style: none;

      & li {
        font-family: $font-family-one;
        font-weight: 300;
        font-size: 14px;
        color: #272727;
        line-height: 24px;
        letter-spacing: .2px;
        transition: all .2s ease-in-out;
      }
    }

  }

}

.page-important {
  padding: 60px 0;
  background: #e0e6ee;

  $g: &;

  &_2 {
    background: #3b3b3d;

    #{$g}__title {
      color: #6ed1cd;
    }

    #{$g}__description {
      color: #6ed1cd;
    }

  }

  &_3 {
    background: #3b3b3d;

    #{$g}__title {
      color: #fff;
    }

    #{$g}__description {
      color: #fff;
    }

  }

  &_4 {
    background: #6ed1cd;

    #{$g}__title {
      color: #3b3b3d;
    }

    #{$g}__description {
      color: #3b3b3d;
    }

  }

  &__text-box {
    padding-right: 40px;

    &_pl {
      padding-left: 40px;
    }

  }

  &__title {
    margin: 0;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 36px;
    color: #3b3b3d;
    line-height: 48px;
    text-transform: uppercase;
    letter-spacing: .2px;
  }

  &__description {
    margin: 30px 0;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 14px;
    color: #3b3b3d;
    line-height: 24px;
    letter-spacing: .2px;
  }

}

.page-important-two {
  position: relative;
  padding-bottom: 100px;
  height: 100%;

  $g: &;

  &:before {
    content: '';
    position: absolute;
    width: 50%;
    height: inherit;
    background: #3b3b3d;
  }

  &_2 {

    &:before {
      background: #6ed1cd;
    }

  }

  &_3 {

    &:before {
      background: #e0e6ee;
    }

    #{$g}__title {
      color: #3b3b3d;
    }

    #{$g}__description {
      color: #3b3b3d;
    }

  }

  &_4 {

    &:before {
      background: #8033e0;
    }

  }

  &__text-wrap {
    display: flex;
    align-items: center;
  }

  &__text-box {
    padding-right: 40px;
  }

  &__title {
    margin: 0;
    font-family: $font-family-one;
    font-weight: 800;
    font-size: 36px;
    color: #fff;
    line-height: 48px;
    text-transform: uppercase;
    letter-spacing: .2px;
  }

  &__description {
    margin: 30px 0;
    font-family: $font-family-one;
    font-weight: 300;
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    letter-spacing: .2px;
  }

  &__form-wrap {
    padding: 100px 0 0 100px;
  }

}

@media only screen and (max-width: 1024px) {

  .page-service-list {
    margin: 40px -15px 0;
  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1024px) {

  .page {
    margin-top: 33px;

    &__title {

    }

  }

  .page-container {
    padding: 0;
  }

  .page-banner {
    margin-top: 30px;
    height: 760px;

    $g: &;

    &__text-box-1 {
      top: 35px;
      margin-left: -200px;
      width: 400px;
      z-index: 1;

      &_1 {
        padding: 110px 20px;
        background: #3b3b3d;

        #{$g}__title {
          color: #fff;
          text-align: center;
        }

      }

      &_2 {
        padding: 100px 50px;
        background: #e0e6ee;

        #{$g}__title {
          text-align: center;
        }

      }

      &_3 {
        padding: 60px 45px 80px;
        background: #3b3b3d;
        z-index: 1;
      }

      &_4 {
        padding: 100px 50px;
        z-index: 1;
        background: #3b3b3d;

        #{$g}__title {
          color: #fff;
          text-align: center;
        }

      }

    }

    &__text-box-2 {
      position: absolute;
      top: 315px;
      left: 50%;
      margin-left: -337px;
      width: 674px;
      background: #fff;

      #{$g}__description {
        text-align: center;
      }

      &_1 {
        padding: 60px;
      }

      &_2 {
        top: 315px;
        margin-left: -337px;
        width: 674px;
        padding: 90px 60px 60px;
      }

    }

    &__title {
      margin: 0;
      font-family: $font-family-one;
      font-weight: 800;
      font-size: 36px;
      color: #3b3b3d;
      line-height: 48px;
      text-transform: uppercase;
      letter-spacing: .2px;

      &_2 {
        color: #fff;
        text-align: center;
      }

    }

    &__description {
      margin: 0;
      font-family: $font-family-one;
      font-weight: 300;
      font-size: 24px;
      color: #3b3b3d;
      line-height: 36px;
      letter-spacing: .2px;
    }

  }

  .page-banner-money {
    height: 690px;

    &__text-box-1 {
      top: 50px;
      left: 50%;
      margin-left: -130px;
      width: 260px;
      height: 260px;
    }

    &__text-box-2 {
      top: 336px;
      left: 50%;
      margin-left: -280px;
      width: 560px;
    }

    &__title {
      top: 110px;
      left: 70px;
      font-size: 35px;
    }

    &__description {
      text-align: center;
    }

  }

  .page-banner-time {
    position: relative;
    height: auto;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #6ed1cd;
    }

    &__circle-wrap {
      padding: 60px 0 20px;
      text-align: center;
    }

    &__circle {
      display: inline-block;
      width: 280px;
      height: 280px;
    }

    &__description-wrap {
      padding: 0 100px 60px;
    }

    &__description {
      text-align: center;
    }

  }

  .page-banner-growth {
    position: relative;
    height: 690px;
    background: #8033e0;

    &__text-box-1 {
      top: 50px;
      left: 50%;
      margin-left: -130px;
      width: 260px;
      height: 260px;
    }

    &__text-box-2 {
      top: 336px;
      left: 50%;
      margin-left: -280px;
      width: 560px;
    }

    &__description {
      text-align: center;
    }

  }

  .page-banner-resources {

    &__description {
      max-width: 550px;
      text-align: center;
    }

  }

  .page-service {
    padding: 50px 0 60px;
    $g: &;

    &_2 {
      padding-top: 50px;

      #{$g}__title {
        color: #3b3b3d;
      }

    }

    &_3 {

      #{$g}__title {
        color: #8033e0;
      }

    }

    &_4 {
      padding-top: 75px;

      #{$g}__title {
        color: #8033e0;
      }

    }

    &__title {
      text-align: center;
    }

    &__btn-wrap {
      padding-left: 100px;
    }

    &__btn {

      & span {
        right: -310px;
        width: 300px;
      }

    }

  }

  .page-service-list {
    justify-content: center;
    margin: 40px 0 0;

    $g: &;

    //&_2 {
    //
    //  #{$g}__link {
    //    border-color: #3b3b3d;
    //
    //    &:hover {
    //      background: #3b3b3d;
    //    }
    //
    //  }
    //
    //}
    //
    //&_3 {
    //
    //  #{$g}__link {
    //    border-color: #8033e0;
    //
    //    &:hover {
    //      background: #8033e0;
    //    }
    //
    //  }
    //
    //}

    &__item {
      width: 100%;
      padding: 0 55px;
      max-width: 530px;
    }

    &__link {
      min-height: 220px;
      padding: 45px;

      & h3 {

      }

      & ul {
        margin: 20px 0 0 0;
        padding: 0;
        list-style: none;

        & li {
          font-family: $font-family-one;
          font-weight: 300;
          font-size: 14px;
          color: #272727;
          line-height: 24px;
          letter-spacing: .2px;
          transition: all .2s ease-in-out;
        }
      }

    }

  }

  .page-important {
    padding: 50px 100px;
    background: #e0e6ee;

    $g: &;

    &_2 {
      background: #3b3b3d !important;
    }

    &_3 {
      background: #3b3b3d;

      #{$g}__title {
        color: #fff;
      }

      #{$g}__description {
        color: #fff;
      }

    }

    &_4 {
      background: #6ed1cd;

      #{$g}__title {
        color: #3b3b3d;
      }

      #{$g}__description {
        color: #3b3b3d;
      }

    }

    &__text-box {
      padding-right: 0;

      &_pl {
        padding-left: 0;
      }

    }

    &__title {
      padding-bottom: 20px;
    }

    &__description {

    }

  }

  .page-important-two {
    padding-bottom: 0;

    &:before {
      content: none;
    }

    &__text-wrap {
      display: flex;
      align-items: center;
      padding: 95px 100px;
      background: #3b3b3d;

      &_1 {
        background: #3b3b3d;
      }

      &_2 {
        background: #6ed1cd;
      }

      &_3 {
        background: #e0e6ee;
      }

      &_4 {
        background: #8033e0;
      }

    }

    &__text-box {
      padding-right: 0;
    }

    &__title {

    }

    &__description {

    }

    &__form-wrap {
      padding: 95px 100px;
    }

  }

}

/* Small Devices, Tablets */
@media only screen and (max-width: 760px) {

  .page {
    margin-top: 20px;

    &__title {
      font-size: 24px;
    }

  }

  .page-container {
    padding: 0;
  }

  .page-banner {
    margin-top: 10px;
    height: 520px;

    $g: &;

    &__text-box-1 {
      top: 30px;
      margin-left: -108px;
      width: 220px;
      z-index: 1;

      &_1 {
        padding: 40px;
        background: #3b3b3d;

        #{$g}__title {
          color: #fff;
          text-align: center;
        }

      }

      &_2 {
        padding: 50px 35px;
        background: #e0e6ee;

        #{$g}__title {
          text-align: center;
        }

      }

      &_3 {
        padding: 30px 20px;
        background: #3b3b3d;
        z-index: 1;
      }

      &_4 {
        padding: 30px 20px;
        z-index: 1;
        background: #3b3b3d;

        #{$g}__title {
          color: #fff;
          text-align: center;
        }

      }

    }

    &__text-box-2 {
      position: absolute;
      top: 167px;
      left: 50%;
      margin-left: -135px;
      width: 270px;
      background: #fff;

      #{$g}__description {
        text-align: left;
      }

      &_1 {
        padding: 50px 15px 40px;
      }

      &_2 {
        top: 160px;
        margin-left: -135px;
        width: 270px;
        padding: 60px 15px 40px;
      }

    }

    &__title {
      font-size: 18px;
      line-height: 32px;

      &_2 {
        color: #fff;
        text-align: center;
      }

    }

    &__description {
      font-size: 14px;
      line-height: 24px;
    }

  }

  .page-banner-money {
    height: 700px;

    &__text-box-1 {
      top: 30px;
      left: 50%;
      margin-left: -130px;
      width: 260px;
      height: 260px;
    }

    &__text-box-2 {
      top: 320px;
      left: 50%;
      margin-left: -130px;
      width: 260px;
    }

    &__title {
      top: 110px;
      left: 70px;
      font-size: 35px;
    }

    &__description {
      text-align: left;
      font-size: 14px;
      line-height: 24px;
    }

  }

  .page-banner-time {
    position: relative;
    height: auto;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #6ed1cd;
    }

    &__circle-wrap {
      padding: 20px;
      text-align: center;
    }

    &__circle {
      display: inline-block;
      width: 220px;
      height: 220px;
    }

    &__description-wrap {
      padding: 0 25px 40px;
    }

    &__description {
      text-align: left;
      font-size: 14px;
      line-height: 24px;
    }

  }

  .page-banner-growth {
    position: relative;
    height: 550px;
    background: #8033e0;

    &__text-box-1 {
      top: 50px;
      left: 50%;
      margin-left: -130px;
      width: 260px;
      height: 260px;
    }

    &__text-box-2 {
      top: 280px;
      left: 0;
      margin-left: auto;
      width: 100%;
    }

    &__title {

    }

    &__description {
      text-align: left;
      padding: 0 25px;
      font-size: 14px;
      line-height: 24px;
    }

  }

  .page-banner-resources {
    height: 520px;

    &__description {
      padding: 0 25px;
      max-width: 550px;
      text-align: left;
      font-size: 14px;
      line-height: 24px;
    }

  }

  .page-service {
    padding: 20px 0 0;

    $g: &;

    &_2 {
      padding-top: 20px;

      #{$g}__title {
        color: #3b3b3d;
      }

    }

    &_3 {

      #{$g}__title {
        color: #8033e0;
      }

    }

    &_4 {
      padding-top: 20px;

      #{$g}__title {
        color: #8033e0;
      }

    }

    &__title {
      padding-left: 25px;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
    }

    &__btn-wrap {
      margin-top: 0;
      padding: 25px;
    }

    &__btn {
      font-size: 16px;

      & span {
        top: 8px;
        right: -110px;
        width: 100px;
      }

    }

  }

  .page-service-list {
    justify-content: center;
    margin: 20px 0 0;

    $g: &;

    //&_2 {
    //
    //  #{$g}__link {
    //    border-color: #3b3b3d;
    //
    //    &:hover {
    //      background: #3b3b3d;
    //    }
    //
    //  }
    //
    //}
    //
    //&_3 {
    //
    //  #{$g}__link {
    //    border-color: #8033e0;
    //
    //    &:hover {
    //      background: #8033e0;
    //    }
    //
    //  }
    //
    //}

    &__item {
      width: 100%;
      padding: 0;
      max-width: 100%;
    }

    &__link {
      min-height: auto;
      padding: 25px;

      & h3 {
        margin: 0;
        font-size: 14px;
        line-height: 24px;
      }

      & ul {
        margin: 20px 0 0 0;
        padding: 0;
        list-style: none;

        & li {
          font-family: $font-family-one;
          font-weight: 300;
          font-size: 14px;
          color: #272727;
          line-height: 24px;
          letter-spacing: .2px;
          transition: all .2s ease-in-out;
        }
      }

    }

  }

  .page-important {
    padding: 25px;
    background: #e0e6ee;

    $g: &;

    &_2 {
      background: #3b3b3d !important;
    }

    &_3 {
      background: #3b3b3d;

      #{$g}__title {
        color: #fff;
      }

      #{$g}__description {
        color: #fff;
      }

    }

    &_4 {
      background: #6ed1cd;

      #{$g}__title {
        color: #3b3b3d;
      }

      #{$g}__description {
        color: #3b3b3d;
      }

    }

    &__text-box {
      padding-right: 0;

      &_pl {
        padding-left: 0;
      }

    }

    &__title {
      padding-bottom: 0;
      font-size: 16px;
      line-height: 24px;
    }

    &__description {
      margin: 20px 0;
    }

  }

  .page-important-two {
    padding-bottom: 0;

    &:before {
      content: none;
    }

    &__text-wrap {
      display: flex;
      align-items: center;
      padding: 25px;
      background: #3b3b3d;

      &_1 {
        background: #3b3b3d;
      }

      &_2 {
        background: #6ed1cd;
      }

      &_3 {
        background: #e0e6ee;
      }

      &_4 {
        background: #8033e0;
      }

    }

    &__text-box {
      padding-right: 0;
    }

    &__title {
      font-size: 16px;
      line-height: 24px;
    }

    &__description {
      margin: 20px 0;
      font-size: 14px;
      line-height: 24px;
    }

    &__form-wrap {
      padding: 25px;
    }

  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}
