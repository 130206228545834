.scroll-wrap {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
}

.scroll-list {
  margin: 0;
  padding: 0;
  list-style: none;

  $g: &;

  &_2 {
    #{$g}__item {
      &_active {
        &:before {
          background: #fff;
        }
      }
    }
  }

  &_3 {
    #{$g}__item {
      &_active {
        &:before {
          background: #8033e0;
        }
      }
    }
  }

  &__item {
    position: relative;
    padding-left: 30px;
    margin-bottom: 31px;

    font-family: $font-family-one;
    font-weight: 800;
    font-size: 16px;
    color: #3b3b3d;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &_active {

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 21px;
        height: 2px;
        background: #6ed1cd;
      }

    }

  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {

  .scroll-wrap {
    display: none;
  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width : 760px) {

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}

