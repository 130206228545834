.logo {
  display: inline-block;
}

.logo-img {
  width: 44px;
  height: 52px;
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

  .logo-img {
    width: 62px;
    height: 76px;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width : 760px) {

  .logo-img {
    width: 35px;
    height: 41px;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}
