.container {
  position: relative;
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

  .container {
    padding: 0 25px;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width : 760px) {

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}


